import React from "react"
import styled from "styled-components"

const Container = styled.div`
  padding-left: 0.3rem;
  margin-top: 0.3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
`

const Ad = styled.div`
  padding: 0.1rem 0.1rem;
  background-color: blue;
  color: white;
  font-size: 0.7rem;
  border-radius: 3px;
  width: 70px;
  text-align: center;
`

const Text = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
`
const TextContainer = styled.div`
  padding: 0.3rem 2rem;
`

export const CvAd = () => {
  const trackClick = (label, category, event) => {
    typeof window !== "undefined" &&
      // @ts-ignore
      window.gtag("event", event, {
        event_label: label,
        event_category: category,
      })
  }
  return (
    <Container onClick={() => trackClick("adCV", "adClick", "blogPage")}>
      {/* <Link href="https://www.cvpohjat.fi/" target="_blank">
        <Image
          width="400px"
          height="192px"
          layout="responsive"
          src="/cv-pohjat.png"
        />
        <Box>
          <Tag colorScheme="green" marginRight="1rem" marginTop="0.3rem">
            Mainos
          </Tag> */}
      <TextContainer>
        <Ad>mainos</Ad>
        <a href="https://www.cvpohjat.fi/" target="_blank">
          <Text>
            Tee visuaalinen CV pohja netissä vain muutamassa minuutissa {">>>"}
          </Text>
        </a>
      </TextContainer>
    </Container>
  )
}
