import React from "react"
import GlobalStyle from "../components/layout/GlobalStyle"
import ResultHeader from "../components/resultComponents/ResultHeader"
import FilterHeader from "../components/compareComponents/FilterHeader"
import styled from "styled-components"
import HeaderWithPhoto from "../components/layout/header/HeaderWithPhoto"
import PageButton from "../components/layout/buttons/PageButton"
import { useProducts } from "../hooks/useProducts"
import { findFilters } from "../utils/helpFunctions"
import { graphql, Link } from "gatsby"
import { device } from "../utils/mediaQueries"
import { Helmet } from "react-helmet"
import { CvAd } from "../components/ads/CvAd"
import { OmaurakkaAd } from "../components/ads/OmaurakkaAd"

const InnerLink = styled(Link)`
  font-weight: 800;
  color: orange;
`

const Header1 = styled.h1`
  color: white;
  font-size: 35px;
  line-height: 3.5rem;
  font-weight: 800;
  text-shadow: 2px 2px 4px #0000008f;
`

const Header2 = styled.h2`
  color: white;
  font-size: 20px;
  line-height: 2.5rem;
  font-weight: 500;
  text-shadow: 2px 2px 4px #0000008f;
  letter-spacing: 0.96px;
`
const Header3 = styled.h3`
  color: black;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.96px;
  text-align: center;
  margin: 30px;
`

const Container = styled.div`
  margin: 4rem 12rem;
  @media ${device.tablet} {
    margin: 20px 30px;
  }
`
const Header4 = styled.h4`
  text-align: center;
`

const TextArea = styled.p`
  margin: 20px 0px;
`
const LoadingText = styled.div`
  font-size: 30px;
  text-align: center;
  margin-top: 30px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px;
`

const ImageBox = styled.div`
  max-height: 200px;
  margin: 2rem;
`

const AdContainer = styled.div`
  padding: 1rem;
  border-radius: 10px;
  background: lightgray;
`

const IndexPage = ({ data }) => {
  const uniqueFilters = findFilters(data)
  const productsHook = useProducts(data.allProductsJson.edges)
  const imageData = data.file.childImageSharp.fluid
  const allProducts = productsHook.filteredProducts
  return (
    <GlobalStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"Tallinnan matkat alkaen 5€ -Lähde edullisesti laivalla Tallinnaan!"}
        </title>
        <meta
          name="description"
          content={
            "Tallinnan matkat alkaen vain 5€. Varaa Helsinki Tallinna risteily, hotellimatka tai vaikka kylpyläloma ja lähde laivalla Viroon Tallinnaan. Katso tarjoukset täältä!"
          }
        />
        <html lang="fi" />
        <meta
          name="google-site-verification"
          content="KvqGQWTk-Oe0KHLc7WrNQWXvdvUkp51uLUXR4urwe8E"
        />
        <meta
          name="tradetracker-site-verification"
          content="b77b2efd875cc0d00a001878035138b61383c285"
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4632341699833963"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <HeaderWithPhoto image={imageData} alt="tallinnaan">
        <Header1>Parhaat Tallinnan matkat löydät täältä</Header1>
        <Header2>Minkälaista matkaa olet etsimässä?</Header2>
        <FilterHeader state={productsHook} filters={uniqueFilters} />
      </HeaderWithPhoto>
      <ImageBox>
        <OmaurakkaAd />
      </ImageBox>
      {productsHook.isLoading ? (
        <LoadingText>Ladataan...</LoadingText>
      ) : (
        <>
          <a name="results"></a>
          <ResultHeader products={allProducts} location="/etusivu/" />
        </>
      )}
      <Container>
        <CvAd />
        <Header4>Tutustu tarkemmin:</Header4>
        <ButtonContainer>
          <PageButton text={"Risteilyt"} url={"/risteilyt/"}></PageButton>
          <PageButton text={"Reittimatkat"} url={"/reittimatkat/"}></PageButton>
          <PageButton text={"Hotellimatka"} url={"/hotellimatka/"}></PageButton>
          <PageButton text={"Kylpylaloma"} url={"/kylpylaloma/"}></PageButton>
          <PageButton
            text={"Äkkilähdöt/tarjoukset"}
            url={"/akkilahdot/"}
          ></PageButton>
          <PageButton text={"Majoitus"} url={"/majoitus/"}></PageButton>
          <PageButton text={"Muut matkat"} url={"/muut/"}></PageButton>
        </ButtonContainer>
        <AdContainer>
          <Header4>
            Kilpailuttamalla kuukausittaiset kulusi voit säästää muutaman
            Tallinan matkan verran rahaa vuodessa
          </Header4>
          <TextArea>
            Kilpailutus kannattaa aina. Ei siis kannata ainoastaan vertailla
            Tallinnan matkojen hintoja vaan kilpailuttaa myös esimerkiksi oma
            sähkölasku sekä puhelinlasku. Voit kilpailuttaa sähkösopimuksen
            osoitteessa{" "}
            <a target="_blank" href="https://www.kilpailutasahkosopimus.fi">
              kilpailutasahkosopimus.fi
            </a>
            ja puhelin liittymien kilpailutus onnistuu osoitteessa{" "}
            <a target="_blank" href="https://www.kilpailutapuhelinliittyma.fi/">
              kilpailutapuhelinliittyma.fi
            </a>
            .
          </TextArea>
        </AdContainer>
        <Header3>Tallinna viehättää nykyaikaistakin matkailijaa</Header3>
        <TextArea>
          Monet suomalaiset osaavat kertoa käyneensä Tallinnassa, mutta he eivät
          silti tiedä, mitä siellä voi todellisuudessa tehdä. Vaikka
          naapurimaamme Viron pääkaupunki onkin niin lähellä, sen paras tarjonta
          on monille jäänyt vieraaksi. Virolaiset ovat kuitenkin hyvin
          samanlaisia kuin suomalaiset, eikä kenenkään kannata vierastaa
          ajatusta Tallinnassa vierailusta, vaikka maidemme lähihistoriat
          ovatkin hyvin erilaisia.
        </TextArea>
        <TextArea>
          Tallinnaan matkustaminen on helppoa ja turvallista, ja sekä
          majoitusvaihtoehtoja että ajanvietettä löytyy monille erilaisille
          matkaajille lapsiperheistä polttariporukoihin. Hintataso on tunnetusti
          edullinen, mutta myös premium-tuotteita ja -kokemuksia on runsaasti
          tarjolla heille, jotka sellaisia kaipaavat. "Tallinnasta ostettu" ei
          enää automaattisesti tarkoita halpaa ja huonoa piraattitavaraa, vaikka
          monilla suomalaisilla onkin yhä sellainen mielikuva. Virolaiset
          arvostavat ihan yhtä lailla laadukasta käsityötä ja tiedostavia
          vaatemerkkejä kuin suomalaisetkin, eikä ole erityisen vaikeaa tehdä
          laadukkaita löytöjä Tallinnan tarjonnasta. Vaikka Viro onkin valtiona
          melko pieni, ei maan pääkaupungista puutu mitään oleellista, mitä
          matkaaja jäisi kovasti kaipaamaan.
        </TextArea>
        <TextArea>
          Viro on sijaintinsa vuoksi aina ollut uniikissa asemassa muuhun
          Eurooppaan nähden, ja se näkyy tietenkin maan historian lisäksi myös
          tallinnalaisessa kulttuurissa ja elämässä. Vaikutteita tulee niin
          Venäjältä ja Itä-Euroopasta kuin täältä Pohjolastakin, mutta myös
          Manner-Euroopasta. Suomalaisten ei siis tarvitse lähteä järin kauas
          löytääkseen eurooppalaista kulttuuria ja tunnelmaa, ja samalla
          hiilijalanjälkikin pysyy pienempänä. Tallinnaan voi matkustaa Suomesta
          niin laivalla kuin lentäenkin, ja kohteessa voi viipyä niin muutaman
          tunnin kuin useammankin yön hotellissa tai kylpylässä. Jatka lukemista
          ja selvitä, mitä kaikkea Tallinnalla on tarjottavana!
        </TextArea>
        <Header3>
          Helposti laivalla Tallinnaan - Helsinki Tallinna alle 4 tunnissa
        </Header3>
        <TextArea>
          <InnerLink to={"/risteilyt/"}>
            Tallinnaan suuntautuvat risteilyt
          </InnerLink>{" "}
          ovat aina olleet suosiossa, ja nykyään eri vaihtoehtoja on niin
          paljon, että sopivia lähtöjä löytynee lähes jokaiseen aikatauluun. Jos
          internetistä löytyvät vaihtoehdot tuntuvat liian runsailta tai
          hankalilta valita, voit suunnata paikalliseen matkatoimistoosi. He
          osaavat kertoa sekä helpoista pakettimatkoista että reittimatkoista
          Helsingistä, ja voit varata samalla niin laivamatkan kuin juna- tai
          bussimatkankin. Jo pelkkä risteily voi olla osa matkan elämystä, sillä
          nykyäänkin risteilylaivoilta löytyy erilaista viihdettä ja monenlaisia
          myymälöitä ja ravintoloita. Useat suomalaiset artistit esiintyvät
          risteilyaluksilla, joten voit yhdistää Tallinnan-reissuun myös
          musiikkielämykset. Jos taas haluat määränpäähäsi mahdollisimman
          nopeasti, on olemassa myös Helsinki-Tallinna-väliä kulkevia
          express-laivoja, joilla matka taittuu parissa tunnissa, etkä joudu
          miettimään hytissä yöpymistä. Nykyään Turustakin pääsee laivalla
          suoraan Tallinnaan, vaikka laivamatka tietenkin vie hieman enemmän
          aikaa kuin Helsingin satamasta lähdettäessä. Suosituiksi
          laivayhtiöiksi voidaan luokitella Silja Linen, Viking Linen tai Eckerö
          Linen. Niiden nettisivujen kautta voi varata liput mukavasti ja
          vaivattomasti.
        </TextArea>
        <TextArea>
          Kun olet varaamassa laivalippuja Tallinnaan, sinä voit päättää, kuinka
          pitkään haluat viettää aikaa maissa. Vaihtoehtoja on parista tunnista
          vaikka koko päivään. Kun taas haluat rentoutua tai kenties juhlia,
          sinua saattavat kiinnostaa yöristeilyt, joilla on yleensä hyvin
          kattava kulttuuriohjelma.
        </TextArea>
        <TextArea>
          Jos olet lähtenyt Suomen puolelta liikkeelle{" "}
          <InnerLink to={"/reittimatkat/auto/"}>omalla autolla</InnerLink>, voit
          ottaa sen autolautalla matkatessa mukaan myös Viron puolelle. Tällöin
          Tallinnassa on erityisen helppoa liikkua, kun et joudu miettimään
          julkisen liikenteen aikatauluja ja reittejä. Ilmaisen langattoman
          nettiyhteyden ansiosta on todella helppoa käyttää vaikka omaa
          puhelinta navigaattorina, mikäli kohteisiin perille löytäminen
          epäilyttää. Useamman henkilön matka saattaa autolla matkatessa olla
          jopa halvempi, sillä toisinaan takapenkin matkustajat pääsevät mukaan
          maksutta. Vertailua kannattaa tehdä etukäteen ja valita omaan
          aikatauluun ja hintatasoon sopivin matka. Lisämaksusta voit
          esimerkiksi varata itsellesi pääsyn maihin ensimmäisten joukossa,
          jolloin et joudu odottamaan autokannella kohtuuttoman pitkään.
          Huomioithan myös, että maihin noustessa autokannella täytyy yleensä
          olla valmiina aiemmin kuin laivalta lähtiessä jalan.
        </TextArea>
        <Header3>Tallinnassa on muutakin kuin satama</Header3>
        <TextArea>
          Risteilymatkat ovat suomalaisten suosituin tapa matkustaa Viroon,
          vaikka risteilymatkojen helppoudessa on se varjopuoli, että matkailija
          ei välttämättä koe tarpeelliseksi poistua laivasta ollenkaan tai mennä
          satamaa kauemmaksi. Se on hyvin harmillista, mutta onneksi myös helppo
          korjata. Jos mielikuvasi Tallinnasta on jäänyt latteaksi ankean
          sataman vuoksi, kannattaa heti seuraavalla matkalla ottaa kohteeksi
          jokin muu paikka Viron pääkaupungissa. Vaikka aikaa maissa ei olisi
          useita tunteja, on satamasta vähän matkan päässä mielenkiintoisia
          kohteita, joihin on helppo suunnata. Jos käyttää aikaa etukäteen
          siihen, että suunnittelee vierailtavat kaupat ja muut kohteet, ei
          aikaa menee turhaan etsiskelyyn tai eksymiseen. Mikäli taas haluaa
          lisäaikaa Tallinnassa vierailuun niin kannattaa ehdostomasti varata{" "}
          <InnerLink to={"/reittimatkat/"}>nopea reittimatka</InnerLink>, juuri
          omien aikataulujen mukaan.
        </TextArea>
        <TextArea>
          Koska Tallinna on niin suosittu matkakohde, erilaisista blogeista ja
          muualta internetistä on hyvin helppoa löytää ajankohtaista ja
          luotettavaa tietoa kaupungista. Jos kuitenkin epäröit lähteä satamasta
          kauemmaksi kaupunkiin, voi ryhmämatka olla sinulle sopiva vaihtoehto,
          sillä isompi ryhmä tietenkin tuo turvaa eikä sinun tarvitse miettiä
          itse, mihin suuntaisit. Esimerkiksi Tallinnan monet ostoskeskukset
          ovat suosittuja ryhmien kohteita.
        </TextArea>
        <TextArea>
          Jos olet aikarajoitteiden tai muiden syiden takia kuitenkin päättänyt
          pysytellä sataman läheisyydessä, ei sinun tarvitse tyytyä Tax Free
          -myymälöiden tarjontaan. Tallinnan sataman lähellä, aivan kävelymatkan
          päässä, on useita monipuolisia ja nykyaikaisia kauppakeskuksia. Aivan
          lähellä on esimerkiksi aikaisemmin Norde Centrumina tunnettu Nautica,
          jossa sijaitsee lähialueen suurin ruokakauppa ja joka on erinomainen
          paikka ruokaostoksille. Vaikka muun muassa kosmetiikan, muodin ja
          elektroniikan hintataso onkin melko lähellä Suomen tasoa, ruoka ja
          elintarvikkeet ovat yhä merkittävästi halvempia. Nauticassa on
          tietenkin myös useita kymmeniä muita liikkeitä, joten muutkin ostokset
          on helppo hoitaa samalla reissulla ja pysähtyä välillä vaikka
          vohvelikahveille. Toinen kävelymatkan päässä satamasta oleva
          kauppakeskus on monille suomalaisille tunnettu Viru-keskus, jonka
          yhteydestä löydät myös Viru-hotellin ja Viron suurimman tavaratalo
          Kaubamajan. Viru-keskuksesta löydät useita kansainvälisiä muodin ja
          muiden tuotteiden ketjuliikkeitä, joita kaikkia ei löydy meiltä
          Suomesta. Muita lähialueella sijaitsevia ostoskeskuksia ovat muun
          muassa Kristiine Keskus ja Rotermann.
        </TextArea>
        <Header3>Tallinnaan lentäen tai junalla</Header3>
        <TextArea>
          Mikäli risteilymatkat eivät innosta tai muuten vain haluat perille
          nopeammin, Tallinnaan pääsee myös lentokoneella. Vaikka et saisi
          kotilentokentältäsi suoraa lentoa Tallinnaan, ei Helsinki-Vantaan
          kautta meneminen vaikeuta matkaa suuresti ja koneen vaihtaminen on
          sujuvaa. Lentokentältä ei kuitenkaan pääse Tallinnan keskustaan yhtä
          helposti kuin satamasta, eikä lentää kannata vain yhden päivän
          pituisen reissun vuoksi. Julkisella liikenteellä pääsee silti
          lentokentältä Tallinnan ytimeen ilman päänvaivaa, sillä linja-autojen
          ja raitiovaunun pysäkit ovat aivan kentän kupeessa. Jos haluat
          mieluummin mennä taksilla, valitse varmuuden vuoksi joku virallisesti
          lentokentän kanssa yhteistyötä tekevä taksi, jotka löydät virallisten
          taksien pysäköintialueelta. Ylipäätään Virossa taksilla liikkuessa
          tulee sopia hinnasta etukäteen, sillä taksimatkojen hintoja ei
          säännöstellä laissa.
        </TextArea>
        <TextArea>
          Suoraa junareittiä Tallinnaan ei valitettavasti vielä ole, mutta
          tunnelia Helsingin ja Tallinnan välille suunnitellaan jo. Sen sijaan,
          jos satut suunnittelemaan matkaa Venäjän puolelle esimerkiksi
          Pietariin, pääsee sieltä junalla Tallinnaan, jos aivan välttämättä
          haluaa matkustaa rautateitse.
        </TextArea>
        <Header3>Hotelliloma Tallinnassa</Header3>
        <TextArea>
          Jos Tallinnan nähtävyyksistä haluaa päästä nauttimaan pidemmän aikaa
          kuin mitä pelkkä{" "}
          <InnerLink to={"/risteilyt/paivaristeily/"}>päiväristeily</InnerLink>{" "}
          sallisi, voi reissua pidentää varaamalla{" "}
          <InnerLink to={"/hotellimatka/"}>
            yhden tai useamman yön hotelliloman
          </InnerLink>
          . Valinnanvaraa on paljon ja vaihtoehtoja löytyy useammalle
          erilaiselle budjetille. Suosituimmat hotellit ovat myös
          mannereurooppalaisten suosiossa, joten muun muassa Viru-hotellin
          aamiaisella voi päästä kuulemaan useita eri kieliä. Viru onkin useille
          Tallinnan-matkaajille tuttu, sillä se on rakennettu jo 1970-luvulla ja
          se oli ensimmäinen tornitalo koko maassa. Nykyään se on osa
          Sokos-hotellien ketjua. Viehättävämpää ja hohdokkaampaa
          hotellikokemusta kaipaavat voivat suunnata Von Stackelbergiin, joka on
          1800-luvulla rakennettuun kaupunkikartanoon sijoitettu hotelli aivan
          vanhankaupungin vieressä. Vanhastakaupungista löytyy myös Savoy
          Boutique Hotel, joka on nimensä mukaisesti putiikkihotelli. Tästä
          pienkokoisesta majoituspaikasta huoneita löytyy vain 44. Jos taas
          halajaa mieluummin ketjuhotelliin, löytyy Tallinnasta tietenkin myös
          Hilton, joka on aivan kaupungin keskustassa ja josta löydät myös
          viihtyisän kylpylän.
        </TextArea>
        <TextArea>
          Koko perheenkin kanssa matkustaville löytyy sopivia hotelleja.
          Telliskiven ja vanhankaupungin läheisyydestä löytyy Go Hotel Shnelli,
          jossa on tavallisten hotellihuoneiden lisäksi myös perhehuoneita.
          Perhehuoneissa on runsaasti tilaa ja niissä on myös keittiö, eikä
          ruokaa tarvitse hakea kaukaa, kun aivan hotellin vierestä löytyy
          ruokakauppa. Kiinnostavat nähtävyydetkään eivät ole kaukana ja
          vieressä on juna-asema.{" "}
        </TextArea>
        <TextArea>
          Jos haluatte majoittua lähemmäksi keskustaa, myös Park Inn Centralista
          löytyy perhehuoneita, joihin mahtuu jopa kuusi henkilöä. Jos taas olet
          lähdössä matkaan puolisosi kanssa ilman lapsia, Tallinnasta löytyy
          mainioita hotelliratkaisuja myös pariskunnille. Esimerkiksi L'Ermitage
          Hotel pitää uniikin ulkokuorensa sisällä skandinaavisen tyylikkäitä
          huoneita, ja varausta tehdessään voi varata hotellin tarjoaman
          Romantiikka-paketin. Kreutzwald Hotel Tallinn sopii hyvin hemmottelua
          kaipaaville pariskunnille, sillä hotellin Zen-huoneet ovat varustettu
          porealtailla. Hotellin spa-osastolta voi myös varata altaita
          yksityiskäyttöön. Viereisestä Centennial-hotellista löytyy myös
          aikuiseen makuun sopivia huoneita. Sieltäkin käsin voi vierailla
          Kreutzwaldin kylpyläosastolla ja varata molemmille hieronnan tai muita
          hemmotteluhoitoja. Voit etsiä lisää hotelli suosituksia meidän{" "}
          <InnerLink to={"/majoitus/"}>majoitus osiosta</InnerLink>.
        </TextArea>
        <Header3>Tallinnan viehättävät kylpylät</Header3>
        <TextArea>
          Vaikka Tallinna ei olekaan Viron kylpyläkaupungeista suosituin, voi
          sinnekin suunnata{" "}
          <InnerLink to={"/kylpylaloma/"}>
            rentouttavalle kylpylälomalle
          </InnerLink>
          . Muihin kaupunkeihin verrattuna Tallinnassa on tietenkin se etu, että
          sinne on kaikkein kätevintä päästä Suomesta. Braavo Spa Hotel
          sijaitsee vanhassakaupungissa ja hotellin rakennus on samantyylinen
          muiden alueen rakennusten kanssa. Huoneet eivät valitettavasti ole
          sisältä yhtä viihtyisiä, mutta kylpylän palvelut ovat silti omaa
          luokkaansa. Allasosastolta löydät yhteensä 11 erilaista allasta ja
          useita eri saunoja, ja samaan hintaan pääset käymään Tallinnan
          suurimmalla kuntosalilla Reval Sportissa. Matkaa satamasta on vain
          reilu kilometri. Vanhankaupungin liepeiltä löytyy myös Meriton Spa,
          josta löydät hieman viehättävämmät hotellihuoneet. Kylpyläosastolta
          löytyy muun muassa kolme erilaista saunaa, erilaisia altaita,
          terapeuttisia hoitoja, hierontaa ja matala lastenallas. Kuntosalin
          käyttäminen kuuluu hintaan myös tässä kylpylähotellissa. Samasta
          rakennuksesta saa halutessaan myös kauneus- ja kampaamopalveluita.
          Kalev Spa -kylpylä on yhtä lailla lähellä vanhaakaupunkia, ja se sopii
          perhehuoneiden ja vesipuistonsa ansiosta myös lapsiperheille.
        </TextArea>
        <TextArea>
          Kauempana Tallinnan keskustan kiireestä, noin 15 minuutin ajomatkan
          päässä, sijaitsee Tallinn Viimsi Spa, joka on todella monipuolinen ja
          moderni kylpylä. Sauna- ja kylpyläkeskuksesta löytyy useita eri uima-
          ja porealtaita sekä saunoja. Kylpylä tarjoaa myös vain aikuisille
          suunnatun 18+-alueen, jonka saunakeskuksessa pääsee nauttimaan muun
          muassa turkkilaisista saunarituaaleista ja ihoa kuorivista
          kalahoidoista. Jos kuitenkin olet matkustamassa koko perheen voimin,
          löytyy perheen pienimmille puuhaa 1 000 neliömetrin kokoisesta
          lastenkeskuksesta. Kylpylän lähellä on myös Viimsin ulkomuseo, joka
          kiinnostanee kaikenikäisiä matkaajia.
        </TextArea>
        <TextArea>
          Jos ylellisten hoitojen ja uiskentelun lisäksi haluaa tuntea historian
          siipien havinaa, on Hotel Telegraaf ehdottomasti yksi parhaista
          vaihtoehdoista. Hotel Telegraaf on putiikkihotelli ja -kylpylä, joka
          on rakennettu entiseen Viron lennättimen puhelinkeskukseen. Hotellin
          huoneet on sisustettu ajan henkeä kunnioittaen. Kylpylästä löydät
          intiimin tunnelman lisäksi eksoottisia hoitoja, ja sen jälkeen voi
          nauttia illallista venäläisissä tunnelmissa ravintola Tchaikovskyssa.
          Historiallisesta tunnelmasta huolimatta kylpylässä voi päästä kokemaan
          todellista luksuselämää.
        </TextArea>
        <Header3>Tallinna on muuttunut ajan saatossa</Header3>
        <TextArea>
          Kun Neuvostoliitto sortui 90-luvun alussa, olivat näkymät Tallinnassa
          hyvin erilaiset kuin nykyään. Jostain syystä tämä mielikuva on silti
          jäänyt pysyväksi: kaupungissa oletetaan edelleen olevan pula ruoasta
          ja kauppatavarasta ja liikkeiden kuvitellaan olevan ankeita ja
          tiukasti säännösteltyjä. Neuvostoliiton vallan alaisuudessa elo on
          nykyajan Virossa kuitenkin muinaishistoriaa, eikä katukuva voisi olla
          kauempana ankeista mielikuvista. Vaikka eri yhteiskuntaluokkien
          väliset tuloerot ovatkin suuria ja köyhyys näkyy ihmisten elämässä
          kaupunkikeskustan ulkopuolella, maa on ottanut hurjia harppauksia
          eteenpäin viimeisen kolmenkymmenen vuoden aikana. 2000-luvulla
          Tallinnakin on aivan nykyaikainen eurooppalainen kaupunki. Julkinen
          liikenne on Tallinnan asukkaille ilmaista, mutta se on turisteillekin
          hyvin helppoa. Pienet lapset ja alle 3-vuotiaan kanssa matkustavat
          pääsevät busseihin ja rautiovaunuihin ilmaiseksi, ja muut voivat
          helposti ostaa lippunsa vaikka verkosta. Tallinn Card -kortilla voit
          matkustaa rajattomasti sen aikaa, kun kortti on voimassa. Tämän
          lisäksi internetin käyttö on erityisen helppoa, sillä käytännössä
          kaikkialla toimii ilmainen langaton netti.
        </TextArea>
        <TextArea>
          Tallinnasta löytyy ajanvietettä historiannälkäisillekin.
          Vanhassakaupungissa on nähtävää paljon ja historiallisia rakennuksia
          ympäröivä tunnelma on ainutlaatuinen. Vanhankaupungin historiallisia
          nähtävyyksiä ovat muun muassa Raatihuone ja Raatihuoneen tori, ja
          alueelta löytyy myös useampia keskiaikaisia kirkkoja.
          Historiallisuudesta huolimatta cappuccinot ja ilmainen wifi eivät ole
          kaukana vanhassakaupungissakaan.
        </TextArea>
        <TextArea>
          Vuonna 1284 perustetun Tallinnan historiaa voi käydä tutkimassa myös
          erilaisissa museoissa, joista merkittävin on Linnamuuseum eli
          Tallinnan kaupunginmuseo. Venäjän vaikutuksesta Viron kulttuuriin ja
          historiaan kiinnostuneen taas kannattaa suunnata Tallinnan venäläiseen
          museoon, joka on aivan kaupunginmuseon ja Viron historiallisen museon
          läheisyydessä. Virolaista taidetta eri aikakausilta voi tutkia muun
          muassa taidemuseo Kumussa, mutta sitä löytyy myös Kadriorgin
          palatsista. Pietari Suuri muinoin perusti palatsin ja sitä ympäröivät
          puutarha-alueet vaimonsa Katariinan kunniaksi, ja alueella saa hyvin
          kulumaan aikaa. Nykyaikana lähistöltä löytyy myös presidentin
          virka-asunto, ja alue on kävelymatkan päässä vanhastakaupungista.
          Museoihin pääsee ilmaiseksi Tallinn Cardilla, mutta huomioithan, että
          museot eivät ole auki aivan jokaisena päivänä.
        </TextArea>
        <Header3>Tallinnan ruokakulttuuri</Header3>
        <TextArea>
          Tallinnasta löytyy nykyaikana niin paljon erilaisia ravintoloita, että
          niistä kertomiseen voisi käyttää kokonaisen päivän. Kaukana ovat
          päivät, kun tallinnalaiset ravintolat ovat jättäneet eurooppalaisen
          matkailijan nälkäiseksi, eikä nykypäivänä kenenkään kannata olla
          huolissaan etteikö sopivaa syötävää löytyisi. Nirsompien ja vähän
          ennakkoluuloisten matkaajien helpotukseksi voidaan kertoa, että
          Tallinnasta löytyy myös suomalaisia ketjuravintoloita, jotka ovat
          Virossa aivan yhtä laadukkaita kuin meillä Suomessakin. Jos vähänkin
          epäilyttää, minkälaista ruokaa pystyy matkan päällä syömään, on
          ravintolat helppo katsastaa etukäteen internetistä. Nykyään lähes
          kaikilta löytyvät ruokalistat nettisivuilta, ja kysymyksiä voi esittää
          vaikka Facebookin kautta. Allergioista ja muista rajoituksista
          huolissaan olevan ei siis tarvitse murehtia pitkään, sillä heillekin
          varmasti löytyy Tallinnasta jotain naposteltavaa, oli sitten kyse
          gluteenittomasta tai vegaanisesta ruokavaliosta. Palvelu Tallinnassa
          on usein hyvin ystävällistä ja osaavaa myös ravintoloissa. Tipin
          antaminen ei ole kovin yleistä, mutta se ei ole kiellettyäkään. Sopiva
          summa on esimerkiksi 10 % lopullisesta laskusta.
        </TextArea>
        <TextArea>
          Jokaisella on omat mielipiteensä ravintoloista, joten objektiivista
          listausta parhaista ravintoloista on hankala antaa varsinkin silloin,
          kun valikoimaa on niin paljon kuin Tallinnassa nykyään. Yksi parhaita
          arvosteluita kerännyt ravintola on nimeltään Rataskaevu 16 ja se
          löytyy keskeltä Tallinnan vanhaakaupunkia. Ravintola on tunnelmallinen
          ja annokset ovat ravintolan suosiosta huolimatta edullisia. Monissa
          Tallinnan ravintoloissa halpoja ruoka-annoksia kompensoidaan
          kalliimmilla viineillä, mutta Rataskaevu 16 -ravintolassa viinitkin
          ovat järkevän hintaisia. Tyyliltään ruoka on vähän parempaa
          tyypillistä virolaista ruokaa, mutta jos kaipaat jotain hieman
          fiinimpää, kokeile samojen omistajien drinkkibaari Tabacia, josta saa
          myös ranskalaistyyppistä bistroruokaa. Jos haluat vähän kauemmaksi
          suosituimpien ravintoloiden hälinästä, kannattaa suunnata Toompean
          mäen toiselle puolelle. Sieltä löydät suomalaisomistuksessa olevan
          Hotokas-ravintolan, joka on erikoistunut street foodiin ja nimenomaan
          hot dogeihin. Samoilta nurkilta löytyy myös ravintola nimeltä
          Kuldmokk, joka tarjoaa ruokalajeja monista eri maista, pääasiassa
          Välimeren seudulta. Hinnat ovat myös kohtuulliset, vaikka ravintola on
          hyvin suosittu.
        </TextArea>
        <TextArea>
          Illallisravintoloiden lisäksi Tallinnasta löytyy tietenkin myös
          aamiais- ja lounasravintoloita. Telliskivestä löytyvä suosittu F-Hoone
          on auki aamusta myöhäisiltaan, joten ehdit käymään siellä syömässä
          vaikka kolmesti saman päivän aikana. Samasta kaupunginosasta löydät
          myös Vurle Cafén, josta saa sekä aamiaista että lounasta hieman
          pienemmällä budjetilla ruokaileville. Täysin erilaisissa maisemissa
          voit nauttia aamiaisesi Nop-ravintolassa, joka löytyy viehättävän
          Kadriorgin alueen läheltä. Nopin hintataso on hieman muita korkeampi,
          mutta ruokakin on tasokasta, ja samasta paikasta löydät myös lounaan
          ja illallisen sekä paikallistenkin suosiossa olevan herkkukaupan,
          mikäli ihastut paikkaan ja haluat palata sinne uudelleen muutenkin
          kuin aamiaiselle. Amerikkalaisen aamiaisen ja diner-tunnelman ystävät
          voivat puolestaan suunnata So Brooklyn -ravintolaan, jonka koko
          sisustus on vaaleanpunaista ja jonka ruokalistalta löydät niin
          amerikkalaiset pancakesit kuin bagelitkin. Jenkkiruoan ystävät
          saattavat muutenkin yllättyä positiivisesti Tallinnan
          ruokatarjonnasta, sillä erilaisia diner-tyyppisiä ravintoloita löytyy
          useita. Monien muiden maiden ruokakulttuurit ovat yhtä lailla
          löydettävissä Tallinnan ravintolamaailmasta.
        </TextArea>
        <Header3>Tallinna lapsiperheille</Header3>
        <TextArea>
          Tallinna on kätevä kohde lapsiperheille, sillä laivalla matkatessa
          kenenkään ei tarvitse istua paikoillaan tuntikausia. Myös liikkuminen
          Tallinnan puolella on helppoa sekä kävellen että julkista liikennettä
          käyttäen. Muistathan kuitenkin hyvissä ajoin ennen matkaa varmistaa,
          että perheen pienimmilläkin on voimassaolevat passit tai
          henkilökortit. Vaikka niitä ei välttämättä tarvitse, oma
          henkilöllisyys täytyy pystyä todistamaan, jos tarkastus sattuu omalle
          kohdalle. Passi täytyy olla myös silloin, kun et aio nousta
          risteilyalukselta maihin. Rokotusohjelmaan kuuluvat rokotteet olisi
          hyvä olla erityisesti pienillä matkustajilla, ja niiden lisäksi
          influenssarokote sekä hepatiitti A- ja B-rokotteet voivat olla
          tarpeellisia. Kaikkiaan Tallinnassa on kuitenkin hyvin turvallista
          matkustaa myös lasten kanssa, eikä erityisiä varotoimia tarvita,
          kunhan noudattaa maalaisjärkeä. Erilaisissa nähtävyyksissä ja
          ravintoloissa lapset on useimmiten huomioitu hyvin, ja
          museotarjonnastakin löytyy myös lastenmuseoita. Esimerkiksi
          Kadriorgista löytyy Miiamilla-lastenmuseo, jossa pääsee vaikka
          askartelemaan. Kalamajan kaupunginosasta löytyvässä lastenmuseossa
          taas lapset pääsevät näkemään vanhoja leluja eri aikakausilta.
        </TextArea>
        <TextArea>
          Jos sää sallii ja haluaa pois sisätiloista, Tallinnasta löytyy
          tekemistä lapsille myös ulkona. Nõmmessä muutaman kilometrin päässä
          keskustasta on seikkailupuisto, jossa on puihin kiinnitettyjä
          erilaisia seikkailuratoja. Lasten radat ovat sopivan alhaalla, jotta
          aikuiset pääsevät kulkemaan rinnalla. Toisaalta, aikuisillekin löytyy
          jännittävämpiä, korkeampia ratoja. Nõmmessä kannattaa viettää
          enemmänkin aikaa, sillä se on Tallinnan keskustaan verrattuna paljon
          rauhallisempi ja viihtyisämpi alue, jossa pääsee suoraan luonnon
          keskelle. Lapsia varmasti kiinnostaa alueelta löytyvä Glehnin puisto
          jännittävine kivipatsaineen. Tutkimisen arvoinen kohde on myös
          Tallinnan eläintarha, joka ei ole aivan lähellä keskustaa, mutta johon
          pääsee usealla eri bussilinjalla. Eläintarha pitää sisällään yli 350
          eri eläinlajia mukaan lukien norsut, joita ei täältä Suomesta
          löydykään. Eläintarhasta löytyy myös pienemmille lapsille suunnattu
          alue, jossa eläimiä pääsee silittämään ja katsomaan lähempää. Kesäisin
          edullinen ulkoilmakohde on Stroomin hiekkaranta, jonka läheltä löytyy
          myös mukavia leikkipuistoja ja pallokenttiä, ja josta löydät evästä
          rannan kioskeista.
        </TextArea>
        <Header3>Pienen budjetin Tallinna</Header3>
        <TextArea>
          Koska Tallinnassa on niin paljon nähtävää myös ulkona eikä kaikista
          nähtävyyksistä tarvitse maksaa, on kaupungissa helppoa matkata myös
          pienellä budjetilla. Tallinn Card säästää rahaa nähtävyyksistä ja
          julkisesta liikenteestä erityisesti silloin, jos haluaa viettää aikaa
          Tallinnassa enemmän kuin muutaman tunnin. Budjettimatkaajille löytyy
          myös kukkarolle sopivia hotelleja, kuten Hestia Hotel Seaport heti
          sataman tuntumassa, vähän viihtyisämpi St Barbara vanhankaupungin
          kupeessa tai pieni ja vähän erikoinen Meriton Old Town Garden, joka
          sijaitsee vanhassa kauppiaan rakennuksessa. Budjettimatkailijan
          kannattaa myöskin tarkistaa parhaat{" "}
          <InnerLink to={"/akkilahdot/"}>
            äkkilähdöt ja tarjoukset Tallinnaan.
          </InnerLink>
        </TextArea>
        <Header3>Tallinna on avoin myös vegaaneille</Header3>
        <TextArea>
          Suomessa viime vuosina kasvanut vegaanitrendi ei ole jättänyt
          Tallinnaakaan välistä. Kaupungista löytyy kokonaan vegaanisia
          ravintoloita, kuten Vegan V, Vegan Inspiratsioon ja Toormoor, mutta
          myös muita ravintoloita, joista voi saada vegaanisia annoksia.
          Vegaaniset herkuttelijat voivat ottaa kohteekseen kahvila Lisannan tai
          Karu Talun, josta löytyy myös vegaaninen herkkukauppa. Illallisruoan
          lisäksi Tallinnasta saa vegaanisena myös pikaruokaa, street foodia ja
          tietenkin aamiaista ja herkkukahveja. Uusia vegaanisia ravintoloita
          perustetaan koko ajan, joten tarjonnan voi tarkistaa internetistä aina
          ennen uutta Tallinnan-reissua.
        </TextArea>
        <Header3>Tallinnan yöelämä</Header3>
        <TextArea>
          Tallinnassa elämä ei pysähdy yölläkään, vaan viihtyä voi
          pikkutunneille asti. Sivistyneitä drinkki- ja viinibaareja löytyy
          erityisesti vanhastakaupungista joka nurkan takaa, mutta
          vauhdikkaampaakin menoa on tarjolla yökerhoissa ja rock-klubeissa.
          Ikärajat ovat samanlaisia kuin Suomessa vastaavanlaisissa baareissa.
          Isommista hotelleista löytyy usein omat baarinsa, jos epäilyttää
          liikkua kaupungissa öiseen aikaan tai haluaa pysytellä hotellin
          läheisyydessä muuten vain. Tallinnasta löytyy myös erilaisia
          teemabaareja sekä tietenkin urheilubaareja, ja monista paikoista saa
          syötävää yölliseenkin nälkään.
        </TextArea>
        <Header3>Tallinnan tapahtumat</Header3>
        <TextArea>
          Kaupungista löytyy erilaisia tapahtumia ympäri vuoden. Laulujuhlat
          Tallinnan laululavalla ovat yksi tunnetuimmista tapahtumista, mutta
          niitä järjestetään vain viiden vuoden välein. Silloin kaupungissa on
          paljon väkeä ympäri maata, mikä kannattaa ottaa huomioon majoitusta
          varatessa. Laululavalla on muulloinkin paljon musiikkitapahtumia koko
          kesän ja ylipäätään erilaisia konsertteja on kaupungissa jatkuvasti.
          Joulukuussa Tallinnaan matkatessa tietenkin viehättää joulumarkkinat
          ja joulutori, jotka järjestetään Raatihuoneen aukiolla
          vanhassakaupungissa. Tunnelmalliset joulumarkkinat kestävät usean
          viikon, joten niille ehtii hyvin jo marraskuun puolella.
        </TextArea>
        <Header3>Ota huomioon Tallinnaan matkatessa</Header3>
        <TextArea>
          Nykypäivänä Tallinnassa on aivan yhtä turvallista kuin missä tahansa
          muussakin Euroopan kaupungissa, mutta matkustajan kannattaa kuitenkin
          pitää järki päässä. Öisin ei kannata eksyä mihin vain ja päivisinkään
          ei ole suositeltavaa jättää tavaroitaan vartioimatta tai levitellä
          rahojaan. Omalla autolla liikkuessa auto on hyvä jättää vartioidulle
          alueelle ja ottaa arvotavarat mukaansa, sillä autovarkaudet eivät ole
          harvinaisia. Taksiyhtiöistä luotettavimmat ovat Tulika Takso ja
          Tallink Takso, mutta kannattaa varoa, ettei erehdy menemään niitä
          jäljitteleviin takseihin.
        </TextArea>
        <Header3>Mitä kieltä Tallinnassa käytetään?</Header3>
        <TextArea>
          90-luvulla Tallinnassa matkustaneilla on sellainen kokemus, että missä
          vain voi puhua suomea, koska tallinnalaiset ovat oppineet sitä
          televisiosta. Nykyään tilanne on kuitenkin toinen, ja vaikka vanhempi
          sukupolvi voi suomea osatakin, ei se ole nuoremmilla tallinnalaisilla
          enää itsestäänselvyys. Jos et osaa puhua viroa etkä venäjää, selviät
          oikein hyvin myös englanniksi. Tärkeimpiä esimerkiksi kauppoihin ja
          liikenteeseen liittyviä sanoja toki kannattaa opetella etukäteen
          viroksi, että kylttejä olisi helpompi lukea ja ymmärtää kaupungissa
          liikkuessaan.
        </TextArea>
        <Header3>Mistä tietoa</Header3>
        <TextArea>
          Luotettavinta ja ajankohtaisinta matkustamiseen liittyvää käytännön
          tietoa voit löytää Tallinnan Suomen suurlähetystön verkkosivuilta, ja
          jos jokin jää epäselväksi, voi matkanjärjestäjäsi antaa tietoa
          esimerkiksi passiasioissa. Suurlähetystöstä on apua myös silloin, kun
          joudut vaikeuksiin matkustaessasi etkä pysty järjestämään asiaa
          paikallisen poliisin kanssa. Lähes kaikkiin asioihin löydät vastauksen
          internetistä: esimerkiksi Tallinnan turismin viralliset sivut
          vastaavat moniin kysymyksiin muun muassa julkiseen liikenteeseen ja
          pääsylippuihin liittyen. Tietoa löytyy tietenkin paljon myös suomeksi
          ja suomalaisten kirjoittamana.
        </TextArea>
        <Header3>Tallinnasta eteenpäin</Header3>
        <TextArea>
          Tallinna on suomalaiselle hyvä portti muuhun Eurooppaan erityisesti
          silloin, jos haluaa välttää lentomatkustusta. Tallinnasta pääsee
          junalla esimerkiksi Berliiniin, vaikka tietenkin matkaan kuluu paljon
          aikaa. Myös bussiyhteydet ovat hyvät, jos haluaa suunnata
          eteläisempään Viroon ja sieltä muihin Baltian maihin. Esimerkiksi
          Varsova ja Praha ovat muita Euroopan kaupunkeja, jotka ovat
          Tallinnasta käsin aivan ulottuvillasi. Jos haluat viedä ekomatkailun
          vielä pidemmälle, on mahdollista lähteä Tallinnasta tietä pitkin
          eteenpäin jopa polkupyörällä, mihin eteläisen Viron pikkutiet ovat
          oikein sopivia. Yksi vaihtoehto on lähteä myös{" "}
          <a href="/muut/riikan-risteily/">Riikaan risteillen tai bussilla</a>.
          Mikäli et halua lähteä Viroa kauemmas niin{" "}
          <a href="/muut/parnun-matkat/">Pärnun matka</a> voisi olla sinulle
          sopivin vaihtoehto. Jos taas Baltia alkaa tuntumaan tutulta, niin
          miten olisi tällä kertaa{" "}
          <a href="/muut/pietarin-risteily/">Pietarin risteily</a>. Kuten näet,
          niin vaihtoehtoja riittää myös hieman Tallinnaa kauempana, mutta
          kuitenkin mukavan lähellä.
        </TextArea>
      </Container>
    </GlobalStyle>
  )
}

export const query = graphql`
  query {
    allProductsJson(filter: { city: { eq: "Tallinna" } }) {
      edges {
        node {
          agency
          auto
          benefits
          city
          country
          departures
          desc
          id
          innerType
          length
          link
          name
          price
          return
          ship
          transport
          type
          slug
          shipImg
          visitTime
        }
      }
    }
    file(base: { eq: "tallinna.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
